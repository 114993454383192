import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { t } from "i18next";
import Section from "./Section";
import CountUp from "react-countup";
import "./extra.scss";

import { useDashboard1Store } from "../../../stores/dashboard1";

import { WaterMaxValues } from "./Charts/WaterMaxChart";
import { WidgetComponent } from "./WidgetTwoCounts";
import moment from "moment";
export default function DashboardOne() {
  const title = t("ADE | Dashboard");
  document.title = title;
  const [fullResCount, setFullResCount] = useState(0);
  const [emptyResCount, setEmptyResCount] = useState(0);
  const [otherResCount, setOtherResCount] = useState(0);
  const [totalNumRes, setTotalNumRes] = useState(0);
  const [totalVolumCount, setTotalVolumCount] = useState(0);

  const [onlineResCount, setOnlineResCount] = useState(0);
  const [offlineResCount, setOfflineResCount] = useState(0);
  const { getDashboard1Data, reservoirs, devices, dayMaxvolumeData } =
    useDashboard1Store((state) => state);

  const widgetsInfo = [
    /*
    {
      id: 2,
      label: "Online |  Offline",
      labelClass: "muted",
      percentage: "",
      percentageClass: "success",
      percentageIcon: "",
      caption: "",
      icon: "bg-success-subtle shadow-none bg-opacity-10",
      counter: onlineResCount,
      counter2: offlineResCount,
      caption: "See details",
      iconClass: "light",
      //bgColor: "bg-info",
      bgColor: "primary",
      textColor: "success",

      textColor2: "danger",

      counterClass: "bg-success-subtle ",
      captionClass: "text-white-50",
      icon: "ri-router-line",
      iconClass: "blue-400",
      decimals: 0,
      prefix: "",
      suffix: "",
      
      subCounter: [
        {
          id: 1,
          counter: totalNumRes,
          decimals: 0,
          suffix: "",
          prefix: "",
        },
        // {
        //   id: 2,
        //   counter: "40",
        //   decimals: 0,
        //   suffix: "sec",
        //   prefix: "",
        // },
      ],
      
    },
   */
  ];
  //dashboard 1 : check the offline/online devices + sync volume total with the online devices only
  // add 2 decimals to the value of total volume
  useEffect(() => {
    console.log(parseFloat(totalVolumCount / 1000).toFixed(2));
  }, [totalVolumCount]);
  const totalWidgets = [
    {
      id: 2,
      label: "Volume Total",
      unit: "3",
      unit_name: "m",
      labelClass: "muted",
      percentage: "",
      percentageClass: "success",
      percentageIcon: "",
      caption: "Afficher le volume total",
      icon: "bg-soft-primary shadow-none bg-opacity-10 ",
      counter:
        totalVolumCount >= 1000000
          ? parseFloat(totalVolumCount / 1000000).toFixed(2)
          : totalVolumCount >= 1000
          ? parseFloat(totalVolumCount / 1000).toFixed(2)
          : totalVolumCount,
      caption: onlineResCount,
      iconClass: "danger-emphasis",
      bgColor: "secondary",
      counterClass: "text-white",
      captionClass: "text-white-50 underline-offset-0",
      icon: "las la-water",
      // iconClass: "blue-50",
      decimals: 2,
      prefix: "",
      suffix:
        totalVolumCount >= 1000000 ? "M" : totalVolumCount >= 1000 ? "K" : "",
    },
    {
      id: 2,
      label: "Réservoirs pleins",
      labelClass: "muted",
      percentage: "(>75%)",
      percentageClass: "success",
      percentageIcon: "",
      caption: "Afficher le volume total",
      icon: "bg-soft-primary shadow-none bg-opacity-10",
      counter: fullResCount,
      caption: "Volume > à 75%",
      iconClass: "light",
      bgColor: "success",
      counterClass: "text-white",
      captionClass: "text-white-50",
      icon: "ri-drop-fill",
      iconClass: "blue-50",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
    {
      id: 2,
      label: "Réservoirs vides",
      labelClass: "muted",
      percentage: "(<20%)",
      percentageClass: "danger",
      percentageIcon: "",
      caption: "Afficher le volume total",
      icon: "bg-soft-primary shadow-none bg-opacity-10",
      counter: emptyResCount,
      caption: "Volume < à 20%",
      iconClass: "light",
      bgColor: "danger",
      counterClass: "text-white",
      captionClass: "text-white-50",
      icon: "ri-contrast-drop-2-line",
      iconClass: "blue-50",
      decimals: 0,
      prefix: "",
      suffix: "",
    },
  ];
  useEffect(() => {
    getDashboard1Data && getDashboard1Data();
    const intervalId = setInterval(getDashboard1Data, 30000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setTotalVolumCount(0);
    //setFullResCount(0)
    setEmptyResCount(0);
    setOfflineResCount(0);
    setOnlineResCount(0);
    setOtherResCount(0);
    if (reservoirs.length > 0) {
      console.log("reservoi array", reservoirs);
      let volume = 0;
      let total_full = 0;
      let total_empty = 0;
      const now = new Date();
      const oneDayAgo = new Date(now);
      oneDayAgo.setDate(now.getDate() - 1);
      reservoirs.map((reservoir) => {
        //const volume = record?.volume;
        console.log("res persentage", reservoir.currentFilledPercentage);
        const lastUpdated = new Date(reservoir.updatedAt);
        if (lastUpdated >= oneDayAgo) {
          volume +=
            reservoir.capacityMeterCubic *
            (reservoir.currentFilledPercentage / 100);
          setTotalVolumCount(volume);

          //only count reservoir which the devices are connected and online

          if (reservoir.currentFilledPercentage > 75) {
            total_full += 1;

            setFullResCount(total_full);
          } else if (reservoir.currentFilledPercentage < 20) {
            total_empty += 1;
            setEmptyResCount(total_empty);
          } else if (20 <= reservoir.filledCapacityMeterCubic <= 70) {
            setOtherResCount((total) => total + 1);
          }
        }
        //return setTotalVolumCount(prev=> prev + parseFloat(record.volume))
      });

      setTotalNumRes(fullResCount + emptyResCount + otherResCount);
    }
  }, [reservoirs]);

  //counting online/ off line devices

  useEffect(() => {
    console.log("devices", devices);
    let total_online = 0;
    let total_offline = 0;
    devices?.map((device) => {
      if (device.status === 1) {
        console.log("the device is on");
        total_online += 1;
        setOnlineResCount(total_online);
      } else {
        console.log("the device is off");
        total_offline += 1;
        setOfflineResCount(total_offline);
      }
    });
  }, [devices]);

  const [dataBarChart, setDataBarChart] = useState([]);
  const [datachartTime, setDatachartTime] = useState([]);

  function sortByTime(data) {
    return data.sort((a, b) => {
      const timeA = new Date(a.maxLevelUpdatedTime).getTime();
      const timeB = new Date(b.maxLevelUpdatedTime).getTime();
      return timeB - timeA;
    });
  }

  useEffect(() => {
    let arrayChart = [];
    let time_array = [];
    const sortedDayMaxVolumeData = sortByTime(dayMaxvolumeData);

    console.log(sortedDayMaxVolumeData);
    let added_entries = 0;
    sortedDayMaxVolumeData.map((e) => {
      if (added_entries === 10) return;
      let time = e.maxLevelUpdatedTime.split("T")[0];

      let [year, month, date] = time.split("-");

      // Create a new Date object with the extracted date and month
      let formattedDate = new Date();
      formattedDate.setDate(date);
      formattedDate.setMonth(month - 1); // JavaScript months are 0-indexed
      // console.log(moment(formattedDate).format("MM-DD"));
      formattedDate = moment(formattedDate).format("MM-DD");
      // time of filled capacity maximum
      if (!time_array.find((date) => date === formattedDate)) {
        time_array.push(formattedDate);
        arrayChart.push(e.maxfilledCapacityMeterCubic.toFixed(2));
        added_entries++;
        console.log(formattedDate, e.maxfilledCapacityMeterCubic);
      } else {
        const indexOfdate = time_array.indexOf(formattedDate);
        if (arrayChart[indexOfdate] > e.maxfilledCapacityMeterCubic) {
          arrayChart[indexOfdate] = e.maxfilledCapacityMeterCubic;
        }
      }
    });
    setDataBarChart(arrayChart);
    setDatachartTime(time_array);
  }, [dayMaxvolumeData]);

  // if(isLoading) return (<div>Is Loading...</div>)
  const datawidget = {
    id: 4,
    cardColor: "info",
    label: "Online |  Offline",
    badgeClass: "muted",
    percentage: "",
    counter: onlineResCount,
    link: "",
    bgcolor: "white-50",
    textColor: "success",
    icon: "ri-router-line",
    decimals: 0,
    prefix: "",
    separator: "",
    suffix: "",
    counter2: offlineResCount,
    decimals2: "0",
    separator2: " ",
    prefix2: "",
    suffix2: "",
    textColor2: "danger",
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Section />

        <div
          className="grid-cols-4 flex-wrap justify-between "
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            borderBottom: "red",
          }}
        >
          {(totalWidgets || []).map((item, key) => (
            <Col xl={4} md={6} key={key}>
              <Card className={"card-animate " + item.bgColor}>
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <p
                        className={
                          "text-uppercase fw-medium mb-0 text-" +
                          item.labelClass
                        }
                      >
                        {item.label}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <h5 className={"fs-14 mb-0 text-" + item.percentageClass}>
                        <i
                          className={
                            "fs-13 align-middle " + item.percentageIcon
                          }
                        ></i>{" "}
                        {item.percentage}
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 className={"fs-22 fw-semibold ff-secondary mb-4 "}>
                        <span className="counter-value" data-target="559.25">
                          <CountUp
                            start={0}
                            prefix={item.prefix}
                            suffix={item.suffix}
                            separator={item.separator}
                            end={item.counter}
                            decimals={item.decimals}
                            duration={1}
                          />
                          &nbsp;&nbsp;
                          {item.unit_name}
                          <sup>{item.unit}</sup>
                        </span>
                      </h4>
                      {/*<Link
                        to="#"
                        className={
                          "text-decoration-underline " + item.captionClass
                        }
                      >
                        {item.caption}
                      </Link>*/}
                      <div className={" " + item.captionClass}>
                        {" "}
                        {item.caption}
                      </div>
                    </div>
                    <div
                      style={{
                        transform: !item.in ? "scaleX(-1)" : " ",
                      }}
                      className="avatar-sm flex-shrink-0"
                    >
                      <span
                        className={
                          "avatar-title rounded fs-3 bg-soft-" + item.bgColor
                        }
                      >
                        <i className={`text-${item.bgColor} ${item.icon}`}></i>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}

          {(widgetsInfo || []).map((item, key) => (
            <Col xl={6} md={6} key={key}>
              <Card className={"card-animate " + item.bgColor}>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className={"fw-medium mb-0 text-" + item.labelClass}>
                        {item.label}
                      </p>
                      <h2
                        className={
                          "mt-4 ff-secondary fw-semibold " + item.counterClass
                        }
                      >
                        {item.subCounter.map((item, key) => (
                          <span className=" text-red-600-!important" key={key}>
                            <CountUp
                              start={0}
                              prefix={item.prefix}
                              suffix={item.suffix}
                              separator={item.separator}
                              end={item.counter}
                              decimals={item.decimals}
                              duration={1}
                            />
                            &nbsp;&nbsp; | &nbsp;&nbsp;
                            <CountUp
                              start={0}
                              prefix={item.prefix}
                              suffix={item.suffix}
                              separator={item.separator}
                              end={item.counter}
                              decimals={item.decimals}
                              duration={1}
                            />
                          </span>
                        ))}
                      </h2>
                      <p className={"mb-0 text-" + item.labelClass}>
                        <span className={"mb-0 badge " + item.badgeClass}>
                          <i className={"align-middle " + item.badge}></i>{" "}
                          {item.percentage}
                        </span>{" "}
                        {/* vs. mois précédent*/}
                      </p>
                    </div>
                    <div>
                      <div
                        className="avatar-sm flex-shrink-0"
                        style={{
                          transform: !item.in ? "scaleX(-1)" : " ",
                        }}
                      >
                        <span
                          className={
                            "avatar-title rounded-circle fs-2 bg-sky-50 " +
                            item.iconClass
                          }
                        >
                          <i
                            className={item.icon + " text-" + item.iconClass}
                          ></i>
                          {/*<svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="20"
                            viewBox="0 0 640 512"
                        >
                            <path
                              fill="#ffffff"
                              d="M112 0C85.5 0 64 21.5 64 48V96H16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 272c8.8 0 16 7.2 16 16s-7.2 16-16 16H64 48c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 240c8.8 0 16 7.2 16 16s-7.2 16-16 16H64 16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 208c8.8 0 16 7.2 16 16s-7.2 16-16 16H64V416c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H112zM544 237.3V256H416V160h50.7L544 237.3zM160 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm272 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z"
                            />
                          </svg>*/}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}

          <WidgetComponent item={datawidget} />

          <WaterMaxValues
            dayMaxVolumData={dataBarChart}
            datatime={datachartTime}
          />
        </div>
      </Container>
    </React.Fragment>
  );
}
