import { useState, useEffect } from "react";
///import { usesitesStore } from "../../stores/Sites";
import { useAlertsStore } from "../../stores/Alerts";
import { t } from "i18next";
import {
  Filter,
  DefaultColumnFilter,
  SelectColumnFilter,
} from "../../Components/Common/filters";
import { WaterFilter2 } from "../Access/History/WaterFilters";
import { useAccessHistoryStore } from "../../stores/AccessHistory";
import { Card, Container, CardBody, CardHeader, Col } from "reactstrap";
import { useAuth } from "../../stores";
import DataTableBase from "../../Components/Common/DataTableBase/DataTableBase";
import { LineChart } from "../Dashboards/Dashboard1/Charts/WaterMaxChart";
import moment from "moment";
import { useDashboard1Store } from "../../stores/dashboard1";
import { useCitiesStore } from "../../stores/Cities";
import { center } from "@turf/turf";
const ReservoirsReport = () => {
  const title = t("ADE | Reports");
  document.title = title;
  const user = useAuth((state) => state.user);
  //const sites=usesitesStore(state=>state.sites)

  const { getAccessHistory, filters, setFilters } = useAccessHistoryStore(
    (state) => state
  );
  const { getDashboard1Data, reservoirs } = useDashboard1Store(
    (state) => state
  );
  const { cities, getCities } = useCitiesStore((state) => state);
  useEffect(() => {
    getDashboard1Data && getDashboard1Data();
    const intervalId = setInterval(getDashboard1Data, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const cancelFilter = () => {
    getAccessHistory({});
    setFilters({});
  };
  const fillInFilters = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    setFilters({ ...filters, [inputName]: inputValue });
  };

  const [optionarraywilaya, setOptionarraywilaya] = useState([]);
  const [optionsarrayIds, setOptionsarrayIds] = useState([
    { label: "1", value: "1" },
  ]);
  useEffect(() => {
    let array = [];
    cities.map((city) => {
      array.push({
        label: city.wilaya_name_ascii,
        value: city.wilaya_code,
      });
      setOptionarraywilaya(array);
    });
  }, [cities]);

  useEffect(() => {
    let array = [];
    reservoirs.map((reservoir) => {
      array.push({
        label: reservoirs.indexOf(reservoir),
        value: reservoir._id,
      });
      setOptionsarrayIds(array);
    });
  }, [reservoirs]);

  const columnsFilters = [
    {
      name: "Date",
      colName: t("Date"),
      onChange: (e) => {
        setFilters({ ...filters, dateFrom: e[0], dateTo: e[1] });
      },
      value: filters.date,

      type: "date",
    },
    {
      name: "ID",
      colName: t("Reservoirs"),
      onChange: fillInFilters,
      placeHolder: "Reservoirs ",
      /*
    selectOptions: [
      { value: true,  id: "ID1" },
      { value: false, id: "ID2" },
    ],
    */
      optionsarray: optionsarrayIds,
      type: "select",
    },

    {
      name: "Site",
      colName: t("Site"),
      onChange: fillInFilters,
      placeHolder: "Site",
      /*
    selectOptions: [
      { value: true, displayText: "Site 1" },
      { value: false, displayText: "Site 2" },
    ],
    */
      optionsarray: [
        {
          label: "Site 1",
          value: "Site1",
        },
        {
          label: "Site 2",
          value: "Site2",
        },
        {
          label: "Site 3",
          value: "Site3",
        },
      ],
      type: "select",
    },
    {
      name: "Région",
      colName: t("Région"),
      onChange: fillInFilters,
      placeHolder: "Région",
      /*
    selectOptions: [
      { value: true, displayText: "Région 1" },
      { value: false, displayText: "SRégion 2" },
    ],
    */
      optionsarray: [
        {
          label: "Région 1",
          value: "Région1",
        },
        {
          label: "Région 2",
          value: "Région2",
        },
        {
          label: "Région 3",
          value: "Région3",
        },
      ],
      type: "select",
    },
    {
      name: "Wilaya",
      colName: t("Wilaya"),
      onChange: fillInFilters,
      placeHolder: "wilaya",
      /*
    selectOptions: [
      { value: true, displayText: "Wilaya 1" },
      { value: false, displayText: "Wilaya 2" },
    ],
    */
      optionsarray: optionarraywilaya,
      type: "select",
    },
    // {
    //   name: "Pourcentage%",
    //   colName: t("Pourcentage%"),
    //   onChange: fillInFilters,
    //   placeHolder: "Pourcentage%",
    //   /*
    // selectOptions: [
    //   { value: true, displayText: "" },
    //   { value: false, displayText: "" },
    // ],
    // */
    //   optionsarray: [
    //     {
    //       label: "20%",
    //       value: "",
    //     },
    //     {
    //       label: "40%",
    //       value: "",
    //     },
    //     {
    //       label: "60%",
    //       value: "",
    //     },
    //   ],
    //   type: "select",
    // },
    // {
    //   name: "Volume vide",
    //   colName: t("Volume vide"),
    //   onChange: fillInFilters,
    //   placeHolder: "Volume m3",
    //   /*
    // selectOptions: [
    //   { value: true, displayText: "Région 1" },
    //   { value: false, displayText: "Région 2" },
    // ],
    // */
    //   optionsarray: [
    //     {
    //       label: "10",
    //       value: "",
    //     },
    //     {
    //       label: "100",
    //       value: "",
    //     },
    //     {
    //       label: "300",
    //       value: "",
    //     },
    //   ],
    //   type: "select",
    // },
    // {
    //   name: "1 Day",
    //   colName: t("1 Day"),
    //   onChange: fillInFilters,
    //   placeHolder: "Interval ",
    //   /*
    // selectOptions: [
    //   { value: true, displayText: "day 1" },
    //   { value: false, displayText: "day 2" },
    // ],
    // */
    //   optionsarray: [
    //     {
    //       label: "1 hour",
    //       value: "",
    //     },
    //     {
    //       label: "1 DAy",
    //       value: "",
    //     },
    //   ],
    //   type: "select",
    // },
  ];

  const filterData = () => {
    getDashboard1Data(filters);
  };

  const columns = [
    {
      name: t("#"),
      width: "50px",
      selector: (row) => reservoirs.indexOf(row) + 1,
      sortable: false,
      wrap: true,
    },

    {
      name: t("Date"),
      width: "150px",
      selector: (row) => moment(row.date).format("DD.MM.YYYY HH:mm"),

      sortable: false,
      wrap: true,
    },
    {
      name: t("Volume Rempli (M3)"),
      // width: "100px",
      selector: (row) => row.currentFilledPercentage * row.capacityMeterCubic,
      sortable: false,
      wrap: true,
      cell: (row) => (
        <strong
          style={{
            margin: "0 auto",
            color: row.currentFilledPercentage === "" && "#7FC7D9",
            textAlign: "left",
          }}
        >
          {parseFloat(
            row.currentFilledPercentage * (row.capacityMeterCubic * 0.01)
          ).toFixed(0)}
        </strong>
      ),
    },

    {
      name: t("Volume vide (M3)"),
      // width: "100px",
      selector: (row) =>
        row.capacityMeterCubic -
        row.currentFilledPercentage * row.capacityMeterCubic,
      sortable: false,
      wrap: true,
      cell: (row) => (
        <strong
          style={{
            margin: "0 auto",
            color:
              row.capacityMeterCubic -
                row.currentFilledPercentage * row.capacityMeterCubic ===
                "NUMILOG" && "#7FC7D9",
          }}
        >
          {parseFloat(
            row.capacityMeterCubic -
              row.capacityMeterCubic * row.currentFilledPercentage * 0.01
          ).toFixed(0)}
        </strong>
      ),
    },
    {
      name: t("Pourcentage Rempli (%)"),
      // width: "100px",
      selector: (row) => row.currentFilledPercentage,
      sortable: false,
      wrap: true,
      cell: (row) => (
        <strong
          style={{
            margin: "0 auto",
            color: row.currentFilledPercentage === "NUMILOG" && "#7FC7D9",
          }}
        >
          {parseFloat(row.currentFilledPercentage).toFixed(0)}
        </strong>
      ),
    },
    {
      name: t("Pourcentage Vide (%)"),
      // width: "100px",
      selector: (row) => 100 - row.currentFilledPercentage,
      sortable: false,
      wrap: true,
      cell: (row) => (
        <strong
          style={{
            margin: "0 auto",
            color: 100 - row.currentFilledPercentage === "NUMILOG" && "#7FC7D9",
          }}
        >
          {parseFloat(100 - row.currentFilledPercentage).toFixed(0)}
        </strong>
      ),
    },
  ];
  const initialData = [
    {
      id: "001",
      date: new Date("2023/08/01"),
      //clientName: "Mahmoud Meky",
      // commercantName: "Amazon EG",
      //typeCommercant: "type Commercant 1",
      // state: "Menofeya",
      // commune: "Shebin",
      // serialNumber: "001001",
      // transaction: "125641",
      // model: "Model-Test-1",
      // version: "1.00",
      // abouties: false,
      //location: [36.737232, 3.086472],
    },

    {
      name: "ID",
      id: "003",
      colName: t("ID"),
      onChange: fillInFilters,
      placeHolder: "ID",
      selectOptions: [
        { value: true, displayText: "ID1" },
        { value: false, displayText: "ID2" },
      ],
      type: "select",
    },

    {
      id: "002",
      date: new Date("2023/08/02"),

      //clientName: ["Mahmoud Meky"],

      //commercantName: "Amazon EG",
      //typeCommercant: "type Commercant 2",
      // state: "Menofeya",
      //commune: "Shebin",
      //serialNumber: "001002",
      // transaction: "125641",
      //model: "Model-Test-2",
      //version: "1.04",
      //abouties: true,
      //location: [35.6967, -0.6417],
    },
  ];
  const [filteredData, setFilteredData] = useState(initialData);
  return (
    <>
      <Col xl={12}>
        <Card>
          <WaterFilter2
            filteredData={columnsFilters}
            allData={initialData}
            onFilterButtonClick={filterData}
            onCancelButtonClick={cancelFilter}
            setFilteredData={setFilteredData}
            fieldsToRender={columnsFilters}
          />
        </Card>
      </Col>
      <Col xl={12}>
        <DataTableBase
          data={reservoirs}
          columns={columns}
          showActionButtons={false}
          showActionColumn={false}
          showSelectBox={false}
          //loading={isLoading}
          // paginationTotalRows={total}
          // onChangePage={onChangePage}
          // onChangeRowsPerPage={onChangeRowsPerPage}
          // onHeaderAddBtnClick={toggleCreateModal}
          onHeaderDeleteBtnClick={() => {
            alert("Soon");
          }}
          // onRowEditBtnClick={setToFalsePositive}
          // onRowDeleteBtnClick={deleteHistory}
          // onSearchIconClick={searchHandler}
          actionColWidth="100px"
          // showSearch={true}
          // showSubHeader={true}
          // showActionButtons={true}
          //onHeaderAddBtnClick={toggleAddModal}
          //onRowEditBtnClick={toggleEditModal}
          //onRowDeleteBtnClick={deleteHistory}
          //showActionColumn={user?.roles?.includes("superAdmin") ? true : false}
          // customActionBtns={(row) => (
          //   <>

          //     <button
          //       className="btn btn-sm btn-danger"
          //       onClick={() => {
          //         showConfirm(() => {
          //           deleteHistory(row._id);
          //         });
          //       }}
          //       title="Delete"
          //     >
          //       <i className="ri-delete-bin-line"></i>
          //     </button>
          //   </>
          // )}
        >
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",

              alignItems: "center",
            }}
          >
            <Col>
              <h4 className="card-title mb-0">Tableau</h4>
            </Col>

            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",

                paddingRight: "0px",
              }}
            >
              {" "}
              <button
                className=" btn btn-secondary waves-effect waves-light "
                //btn btn-soft-secondary waves-effect material-shadow-none
                style={
                  {
                    // right: isRtl ? null : 20,
                    // left: isRtl ? 20 : null,
                    //top: 12,
                    //zIndex: 2,
                  }
                }
                // onClick={props.toggleMapTable}
              >
                {t("Download")}
              </button>
            </Col>
          </CardHeader>
        </DataTableBase>
      </Col>
    </>
  );
};
export default ReservoirsReport;
