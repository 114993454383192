import { create } from "zustand";

export const useAlertsStore= create((set,get)=>({
    alerts : [
         
            {
              id: 1,
              type: "fire",
              description: "Active fire reported",
              location: {
                coordinates: [36.7547, 3.0420], // Coordinates for an alert in Algiers
              },
            },
            {
              id: 2,
              type: "flood",
              description: "Flash flood warning",
              location: {
                coordinates: [35.6953, -0.6291], // Coordinates for an alert in Oran
              },
            },
            {
              id: 3,
              type: "traffic",
              description: "Major traffic accident",
              location: {
                coordinates: [36.3654, 6.6154], // Coordinates for an alert in Constantine
              },
            },
        
          
        
          
          ]
          
      
      
}))