/* eslint-disable no-debugger */
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { CustomCard } from "../../../Components/Common/CustomCard";
import ReactSelect from "react-select";

import {
  prepareSelectOptions,
  reactSelectStyling,
} from "../../../Components/Common/Forms/FormikInputHelper";
import styled from "styled-components";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { Button } from "reactstrap";
export const WaterFilter2 = ({
  onCancelButtonClick,
  onFilterButtonClick,
  fieldsToRender,
}) => {
  // const allSelectOptions = prepareAllSelectOptions(allData);
  /*const fieldsToRender = [
    {
      options: ["1","2","3","4","5","6"],
      placeholder: t("ID"),
      name: "id",
    },

    {
      options: allSelectOptions.clientOptions,
      placeholder: t("Site"),
      name: "Site",
    },
    {
      options: allSelectOptions.commercantOptions,
      placeholder: t("Région"),
      name: "Région",
    },
    {
      options: allSelectOptions.typeCommercantOptions,
      placeholder: t("wilaya"),
      name: "Wilaya",
    },
    {
      options: allSelectOptions.communeOptions,
      placeholder: t("Commune"),
      name: "commune",
    },
    {
      options: allSelectOptions.serialNumberOptions,
      placeholder: t("Pourcentage%"),
      name: "pourcentage",
    },
    {
      options: allSelectOptions.modelOptions,
      placeholder: t("Volume vide"),
      name: "volumevide",
    },
    {
      options: allSelectOptions.aboutiesOptions,
      placeholder: t("1 Day"),
      name: "day",
    },
  ];
  */
  const [showFilters, setShowFilters] = useState(true);
  const [filterValues, setFilterValues] = useState({});

  /*
  const filterDataHandler = () => {
    let filteredData = [];
    const filteredDataWithoutDates = allData.filter((item) => {
      for (const key in filterValues) {
        if (["dateFrom", "dateTo"].includes(key)) {
          return true;
        }
        if (filterValues[key] !== undefined) {
          if (filterValues[key] !== item[key]) {
            return false;
          }
        }
      }
      return true;
    });
    filteredData = filteredDataWithoutDates;
    // Filter Date with Dates
    if (filterValues.dateFrom && filterValues.dateTo) {
      const filteredDataWitDates = filteredDataWithoutDates.filter((item) => {
        const itemDate = moment(item.date);
        const dateFrom = moment(filterValues.dateFrom);
        const dateTo = moment(filterValues.dateTo);

        if (
          itemDate.isSameOrBefore(dateTo) &&
          itemDate.isSameOrAfter(dateFrom)
        ) {
          return true;
        }
        return false;
      });
      filteredData = filteredDataWitDates;
    }
    setFilteredData(filteredData);
  };
*/

  return (
    <CustomCard>
      <div className="d-flex justify-content-between">
        <h4 className="text-muted">{t("Filtres")}</h4>
        <button
          className="btn  p-0 text-muted border-0"
          onClick={() => {
            setShowFilters(!showFilters);
          }}
        >
          {showFilters ? t("Masquer") : t("Afficher")}
        </button>
      </div>
      <hr className="m-0" />

      <div
        className="mt-3"
        style={{
          height: showFilters ? "auto" : 0,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        {showFilters && (
          <div className="d-flex flex-wrap gap-2">
            <StyledFilterWrapper>
              <div className="input-group">
                <Flatpickr
                  className="form-control border-0 dash-filter-picker shadow"
                  options={{
                    mode: "range",
                    dateFormat: "m d, Y",
                    // defaultDate: ["01 Jan 2022", "31 Jan 2022"],
                    maxDate: new Date(),
                  }}
                  placeholder={t("Date")}
                  onClose={(dates) => {
                    fieldsToRender[0].onChange(dates);
                    setFilterValues({
                      ...filterValues,
                      dateFrom: dates[0],
                      dateTo: dates[1],
                    });
                  }}
                />
                <div className="input-group-text bg-primary border-primary text-white ">
                  <i className="ri-calendar-2-line"></i>
                </div>
              </div>
            </StyledFilterWrapper>

            {fieldsToRender.map((field, index) => {
              if (field.optionsarray?.length > 0) {
                return (
                  <StyledFilterWrapper key={index}>
                    <ReactSelect
                      {...reactSelectStyling}
                      options={field.optionsarray}
                      //options={field.selectOptions}
                      isSearchable={true}
                      placeholder={field.placeHolder}
                      isClearable={true}
                      name={field.name}
                      // value={filterValues[field.name]?.value}
                      onChange={(option) => {
                        //check if the option is null

                        field.onChange(option);
                      }}
                    />
                  </StyledFilterWrapper>
                );
              }
            })}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <Button
                style={{
                  //width: "195px",
                  width: "150px",
                  height: "34px",
                  //position:"absolute",
                  //bottom:"10px",
                  //left:"0px",
                  //right:"0px",
                }}
                onClick={onFilterButtonClick}
              >
                {t("Valider")}
              </Button>
              <Button
                className="bg-danger"
                style={{ width: "150px", height: "34px" }}
                onClick={onCancelButtonClick}
              >
                {t("Réinitialiser")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </CustomCard>
  );
};

const StyledFilterWrapper = styled.div`
  min-width: 200px;
  // flex: 1;
`;

const prepareAllSelectOptions = (data) => {
  const idsOptions = prepareSelectOptions(data, "id");

  const aboutiesOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  return {
    idsOptions,
  };
};
