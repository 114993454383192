/* eslint-disable no-debugger */
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { CustomCard } from "../../../Components/Common/CustomCard";
import ReactSelect from "react-select";

import {
  prepareSelectOptions,
  reactSelectStyling,
} from "../../../Components/Common/Forms/FormikInputHelper";
import styled from "styled-components";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { Button } from "reactstrap";
export const Dashboard2Filter = ({
  onCancelButtonClick,
  onFilterButtonClick,
  fieldsToRender,
}) => {
  const [showFilters, setShowFilters] = useState(true);
  const [filterValues, setFilterValues] = useState({});

  return (
    <CustomCard>
      <div className="d-flex justify-content-between">
        <h4 className="text-muted">{t("Filtres")}</h4>
        <button
          className="btn  p-0 text-muted border-0"
          onClick={() => {
            setShowFilters(!showFilters);
          }}
        >
          {showFilters ? t("Masquer") : t("Afficher")}
        </button>
      </div>
      <hr className="m-0" />

      <div
        className="mt-3"
        style={{
          height: showFilters ? "auto" : 0,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <div className="d-flex flex-wrap gap-2">
          {showFilters && (
            <>
              {fieldsToRender.map((field, index) => {
                if (field.optionsarray?.length > 0) {
                  return (
                    <StyledFilterWrapper key={index}>
                      <ReactSelect
                        {...reactSelectStyling}
                        options={field.optionsarray}
                        //options={field.selectOptions}
                        isSearchable={true}
                        placeholder={field.placeHolder}
                        isClearable={true}
                        name={field.name}
                        // value={filterValues[field.name]?.value}
                        onChange={(option) => {
                          //check if the option is null

                          field.onChange(option);
                        }}
                      />
                    </StyledFilterWrapper>
                  );
                }
              })}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px ",
                  justifyContent: "end",
                  width: "100%",
                }}
              >
                <Button
                  style={{
                    //width: "195px",
                    width: "150px",
                    height: "34px",
                    //position:"absolute",
                    //bottom:"10px",
                    //left:"0px",
                    //right:"0px",
                  }}
                  onClick={onFilterButtonClick}
                >
                  {t("Valider")}
                </Button>
                <Button
                  className="bg-danger"
                  style={{ width: "150px", height: "34px" }}
                  onClick={onCancelButtonClick}
                >
                  {t("Réinitialiser")}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </CustomCard>
  );
};

const StyledFilterWrapper = styled.div`
  min-width: 200px;
  // flex: 1;
`;
