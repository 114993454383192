import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { filterNonFalseValues } from "./helpers/Utlis";

export const useWaterStore = create((set, get) => ({
  history: [],
  isLoading: false,
  isError: false,
  error: undefined,
  resultsCount: 0,
  filters: {
    ID: "",
    Site: "",
    Region: "",
    Wilaya: "",
    Pourcentage: 0,
    Volume_vide: 0,
    Interval: "",
    dateFrom: "",
    dateTo: "",
  },

  reservoirsOnline: [],
  reservoirsOffline: [],
  //methods

  getReservoirHistory: async (filters) => {
    set({ filters: filterNonFalseValues(filters) });
    const body = {
      search: filterNonFalseValues(filters)?.search,
      ...filters,
    };

    try {
      set({ isLoading: true });
      let response = await axiosHelper.post("/system/history/get", body);
      if (!response.result) {
        return;
      }

      set({
        history: response.data,
        resultsCount: response.count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
  getChartColorsArray: (colors) => {
    colors = JSON.parse(colors);
    return colors.map(function (value) {
      var newValue = value.replace(" ", "");
      if (newValue.indexOf(",") === -1) {
        var color = getComputedStyle(document.documentElement).getPropertyValue(
          newValue
        );

        if (color.indexOf("#") !== -1) color = color.replace(" ", "");
        if (color) return color;
        else return newValue;
      } else {
        var val = value.split(",");
        if (val.length === 2) {
          var rgbaColor = getComputedStyle(
            document.documentElement
          ).getPropertyValue(val[0]);
          rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
          return rgbaColor;
        } else {
          return newValue;
        }
      }
    });
  },
}));
