import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import { useWaterStore } from "../../stores/WaterTank";
import moment from "moment";

export const ZoomableTimeseries = ({ isLoading, history, dataColors }) => {
  const getChartColorsArray = useWaterStore(
    (state) => state.getChartColorsArray
  );
  const lineChartColor = getChartColorsArray(dataColors);
  var ZoomableTimeseriesColors = getChartColorsArray(dataColors);
  const formatingData = (type) => {
    const InterpolatedData = [];

    // Collecting and formatting data
    Object.keys(history).forEach((date) => {
      Object.keys(history[date]).forEach((data) => {
        const x = new Date(`${date} ${data}:00`).getTime();
        let y;
        if (type === "filled") {
          y = parseFloat(history[date][data].averageFilledCubic);
        } else {
          y = parseFloat(history[date][data].averageEmptyCubic);
        }
        InterpolatedData.push({ x, y });
      });
    });

    // Sorting data by timestamp
    InterpolatedData.sort((a, b) => a.x - b.x);

    return InterpolatedData;
  };

  const series = [
    {
      name: "Rempli",
      data: formatingData("filled"),
      color: "#236bb8", // Blue color for the first data series
    },
    {
      name: "Vide",
      data: formatingData("empty"),
      color: "#de6c33", // Orange color for the second data series
    },
  ];

  var options = {
    chart: {
      type: "area",
      stacked: false,
      height: 500,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    colors: ZoomableTimeseriesColors,
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "Water Volume changes ",
      align: "left",
      style: {
        fontWeight: 500,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      showAlways: true,
      labels: {
        show: true,
        formatter: function (value) {
          return Math.round(value); // Rounds the value to remove decimals
        },
      },
      title: {
        text: "Volume (m³)",
        style: {
          fontWeight: 500,
        },
      },
    },
    xaxis: {
      type: "datetime",
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return val.toFixed(0) + " m³";
        },
      },
      x: {
        formatter: function (val) {
          const date = new Date(val);
          const month = date.toLocaleString("default", { month: "short" });
          const day = date.getDate();
          const hour = date.getHours();
          return `${day} ${month} ${hour}:00`;
        },
      },
    },
  };

  return (
    <React.Fragment>
      {!isLoading ? (
        <ReactApexChart
          dir="ltr"
          options={options}
          series={series}
          type="area"
          height={450}
          className="apex-charts"
        />
      ) : (
        <div
          style={{
            height: "450px",
            display: "flex",
            alignItems: "center",
          }}
          className="flex  items-center justify-content-center "
        >
          <div className="spinner-border text-primary">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
