import { create } from "zustand";
import { axiosHelper } from "./helpers/axios_helper";
export const useDashboard1Store = create((set, get) => ({
  reservoirs: [],
  devices: [],
  dayMaxvolumeData: [],
  filters: {
    commune_name_ascii: "",
    commune_name: "",
    daira_name_ascii: "",
    daira_name: "",
    wilaya_name_ascii: "",
    wilaya_name: "",
    Site: "",
    Région: "",
    Volume_vide: "",
    Interval: "",
    Pourcentage: "",
  },

  isLoading: false,
  isError: false,
  error: undefined,
  reservoirsOnline: 0,
  reservoirsOffline: 0,
  fullResCount: 0,
  emptyResCount: 0,

  getDashboard1Data: async (filters) => {
    const body = {
      // search: filterNonFalseValues(filters) || "",
      ...filters,
      page: filters?.page || 1,
      limit: filters?.limit || 10,
      sortDirection: "desc",
    };
    set({ isLoading: true });
    let response = await axiosHelper.post(
      "/system/dashboard/dashboard11",
      body
    );

    set({ reservoirs: response[0].data, isLoading: false });
    set({ devices: response[1].data, isLoading: false });
    set({ dayMaxvolumeData: response[2].data, isLoading: false });
    if (!response.result) {
      return;
    }
    // console.log(response.data);

    set({ Dadhboard1Data: response, isLoading: false });
    //on doit calculer le volum d'eau disponible dans chaque réservoir ,

    set({ isLoading: false });
  },

  getCities: async (filters) => {
    const body = {
      // search: filterNonFalseValues(filters) || "",
      ...filters,
      page: filters?.page || 1,
      limit: filters?.limit || 10,
      sortDirection: "desc",
    };
    set({ isLoading: true });
    let response = await axiosHelper.post("/system/cities/get", body);

    if (!response.result) {
      return;
    }
    // console.log(response.data);

    set({ cities: response.data, isLoading: false });

    set({ isLoading: false });
  },

  //methods
  contEmptyFullRes: async () => {
    console.log("we are counting empty and full reservoirs");
    useDashboard1Store.reservoirs?.map((reservoir) => {
      if (reservoir.capacityMeterCubic > 500) {
        console.log("full", reservoir.capacityMeterCubic);
        //count1= count1+1
        // set({fullResCount: 1, isLoading: false });
        //setFullResCount(fullResCount+1)
      } else if (reservoir.capacityMeterCubic < 500) {
        // setEmptyResCount(emptyResCount+1)
        //console.log("empty", emptyResCount)
        console.log("Empty", reservoir.capacityMeterCubic);
      }
    });
  },

  getChartColorsArray: (colors) => {
    colors = JSON.parse(colors);
    return colors.map(function (value) {
      var newValue = value.replace(" ", "");
      if (newValue.indexOf(",") === -1) {
        var color = getComputedStyle(document.documentElement).getPropertyValue(
          newValue
        );

        if (color.indexOf("#") !== -1) color = color.replace(" ", "");
        if (color) return color;
        else return newValue;
      } else {
        var val = value.split(",");
        if (val.length === 2) {
          var rgbaColor = getComputedStyle(
            document.documentElement
          ).getPropertyValue(val[0]);
          rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
          return rgbaColor;
        } else {
          return newValue;
        }
      }
    });
  },
}));
