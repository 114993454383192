import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import DatePicker from "../../../Components/Common/DatePicker";

const Section = () => {
  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">Marhaba, Ilyes!</h4>
              <p className="text-muted mb-0">
              Votre résumé pour aujourd’hui.
              </p>
            </div>
            
           {/* <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-sm-auto">
                    <div className="input-group">
                      <DatePicker />
                      <div className="input-group-text bg-primary border-primary text-white">
                        <i className="ri-calendar-2-line"></i>
                      </div>
                    </div>
                  </div>
                </Row>
              </form>
  </div>*/}
          
          
          
          
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
