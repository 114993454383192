import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { filterNonFalseValues } from "./helpers/Utlis";

export const useReservoirsStore = create((set, get) => ({
  reservoirs: [],
  isLoading: false,
  isError: false,
  error: undefined,
  filters: {
    commune_name_ascii: "",
    commune_name: "",
    daira_name_ascii: "",
    daira_name: "",
    wilaya_name_ascii: "",
    wilaya_name: "",
    Site: "",
    Région: "",
    Volume_vide: "",
    Interval: "",
    Pourcentage: "",
  },
  resultsCount: undefined,
  // Methods
  getReservoirs: async (filters) => {
    set({ filters: filterNonFalseValues(filters) });
    const body = {
      //search: filterNonFalseValues(filters)?.search,
      ...filters,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "",
      // dateTo: "",
      page: filters?.page || 1,
      limit: filters?.perPage || 10,
    };
    //
    try {
      set({ isLoading: true });
      let response = await axiosHelper.post("/system/reservoir/get", body);

      if (!response.result) {
        return;
      }
      //

      set({
        reservoirs: response.data,
        resultsCount: response.count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
  createReservoir: async (body) => {
    body.lastOnline = new Date();
    set({ isLoading: true });
    try {
      let response = await axiosHelper.post("/system/reservoirs/create", body);
      if (!response.result) {
        return;
      }
      get().getReservoirs();
      return response.result;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },

  //   editReservoirs: async (id, body) => {
  //     // console.log(id)
  //     // set({ isLoading: true });
  //     try {
  //       let response = await axiosHelper.put(`/system/reservoirs/update/${id}`, body);
  //       if (!response.result) {
  //         return;
  //       }

  //       get().getReservoirs();

  //       return response;
  //     } catch (error) {
  //       return error.response.data.message;
  //     } finally {
  //       set({ isLoading: false });
  //     }
  //   },
  //   deleteReservoir: async (id) => {
  //     //
  //     // set({ isLoading: true });
  //     try {
  //       let response = await axiosHelper.delete(`/system/reservoirs/delete/${id}`);
  //       if (!response.result) {
  //         return;
  //       }
  //       // get().fetchTenants(get().filters);
  //       // get().getReservoirs();
  //       set(
  //         (state) =>
  //           (state.Reservoirs = state.Reservoirs.filter((reservoir) => reservoir._id !== id))
  //       );
  //       return response;
  //     } catch (error) {
  //       return error.response.data.message;
  //     } finally {
  //       set({ isLoading: false });
  //     }
  //   },
}));
