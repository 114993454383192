import React, { useEffect, useState } from "react";
import { useCitiesStore } from "../../../stores/Cities";
import { useDashboard1Store } from "../../../stores/dashboard1";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Dashboard2Filter } from "./dashboard2Filter";
import { t } from "i18next";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { useWaterStore } from "../../../stores/WaterTank";
import PieChart from "./PieChart";
import { usesitesStore } from "../../../stores/SiteStore";
import { useReservoirsStore } from "../../../stores/Reservoirs";

const Dashboard2 = () => {
  const { getCities, cities } = useCitiesStore((state) => state);

  // const { getDashboard1Data, reservoirs } = useDashboard1Store(
  //   (state) => state
  // );
  const { getReservoirs, reservoirs } = useReservoirsStore((state) => state);
  const [initialLoad, setInitialLoad] = useState(true);
  const [reservoirsList, setReservoirsList] = useState([]);
  const { sites, getSites } = usesitesStore((state) => state);

  var chartDonutBasicColors = getChartColorsArray(
    '[ "--vz-success", "--vz-danger", "--vz-info"]'
  );

  useEffect(() => {
    let deadline = new Date();
    let freshReservoirs = [];
    deadline.setDate(deadline.getDate() - 1);
    reservoirs.map((res) => {
      if (new Date(res.updatedAt).getTime() > deadline.getTime()) {
        freshReservoirs.push(res);
      }
    });
    setReservoirsList(freshReservoirs);
    console.log(freshReservoirs);
  }, [reservoirs]);
  const [pagefilters, setpageFilters] = useState({});
  function formatM3(value) {
    // Convert the value to string
    value = String(value);

    // Split the string into parts separated by every three characters
    let parts = [];
    while (value.length > 3) {
      parts.unshift(value.slice(-3));
      value = value.slice(0, -3);
    }
    parts.unshift(value);

    // Join the parts with space as separator
    return parts.join(" ");
  }

  var options = {
    chart: {
      height: 300,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },

    colors: chartDonutBasicColors,
    labels: ["Volume Rempli", "Volume Vide"],
    noData: { text: "Aucune Donnee!" },
    tooltip: {
      y: {
        formatter: function (value) {
          return formatM3(value) + " m³";
        },
      },
    },
  };
  useEffect(() => {
    getReservoirs && getReservoirs();
    getCities && getCities(); // fetch data from backend  eavry 5 seconds
    getSites && getSites();
  }, []);

  const cancelFilter = () => {
    setpageFilters({});
    getReservoirs({});
  };
  const [optionarraywilaya, setOptionarraywilaya] = useState([
    {
      label: "loading",
      value: "1",
    },
  ]);
  const [optionarrayDaira, setOptionarrayDaira] = useState([
    {
      label: "loading",
      value: "1",
    },
  ]);

  const [optionarrayCommune, setOptionarrayCommune] = useState([
    {
      label: "loading",
      value: "1",
    },
  ]);
  const [siteOptions, setSiteOptions] = useState([
    {
      label: "loading",
      value: "1",
    },
  ]);
  const [optionsarrayIds, setOptionsarrayIds] = useState([
    {
      label: "none",
      value: "1",
    },
  ]);
  const [reservoirsOptionsInfo, setReservoirsOptionsInfo] = useState([]);
  const [selectedWilaya, setSelectedWilaya] = useState("");
  const [selectedDaira, setSelectedDaira] = useState("");
  const [selectedCommune, setSelectedCommune] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedReservoir, setSelectedReservoir] = useState("");

  const defaultCityPopulation = () => {
    let wilayas = [];
    let dairas = [];
    let communes = [];
    cities.map((city) => {
      // remoove repeated wilaya names and set the wilaya option wilayas
      if (
        !wilayas.some(
          (item) =>
            item.label === `${city.wilaya_code}-${city.wilaya_name_ascii}` &&
            item.value === city.wilaya_code
        )
      ) {
        {
          wilayas.push({
            label: `${city.wilaya_code}-${city.wilaya_name_ascii}`,
            value: city.wilaya_code,
          });
        }
        wilayas.sort((a, b) => a.value - b.value);
        setOptionarraywilaya(wilayas);
      }
      //
      if (!dairas.some((item) => item.label === city.daira_name_ascii)) {
        dairas.push({
          label: city.daira_name_ascii,
          value: city.daira_name_ascii,
        });

        setOptionarrayDaira(dairas);
      }
      if (!communes.some((item) => item.value === city._id)) {
        communes.push({
          label: city.commune_name_ascii,
          value: city._id,
        });

        setOptionarrayCommune(communes);
      }
    });
  };

  const defaultSitePopulation = () => {
    const formattedSites = sites.map((site) => {
      return {
        label: site.siteName,
        value: site._id,
      };
    });
    setSiteOptions(formattedSites);
  };
  const defaultReservoirPopulation = () => {
    let array = [];
    reservoirsOptionsInfo.map((reservoir) => {
      array.push({
        label: reservoir?.name,
        value: reservoir._id,
      });
      setOptionsarrayIds(array);
    });
  };
  useEffect(() => {
    defaultCityPopulation();
  }, [cities]);
  useEffect(() => {
    defaultSitePopulation();
  }, [sites]);

  useEffect(() => {
    if (initialLoad && reservoirs.length > 0) {
      console.log("in");
      console.log(reservoirs);
      setReservoirsOptionsInfo(reservoirs);

      setInitialLoad(false);
      console.log(reservoirsOptionsInfo);
    }
  }, [reservoirs]);

  useEffect(() => {
    defaultReservoirPopulation();
  }, [reservoirsOptionsInfo]);
  useEffect(() => {
    if (selectedWilaya) {
      let dairas = [];
      let communes = [];
      setOptionarrayDaira([
        {
          label: "none",
          value: "1",
        },
      ]);
      setOptionarrayCommune([
        {
          label: "none",
          value: "1",
        },
      ]);
      cities.map((city) => {
        if (city.wilaya_code === selectedWilaya) {
          if (!dairas.some((item) => item.label === city.daira_name_ascii)) {
            dairas.push({
              label: city.daira_name_ascii,
              value: city.daira_name_ascii,
            });
          }
          if (!communes.some((item) => item.value === city._id)) {
            communes.push({
              label: city.commune_name_ascii,
              value: city._id,
            });
          }
        }
      });

      if (communes.length > 0) {
        setOptionarrayCommune(communes);
        const newSiteOptions = sites
          .filter((site) =>
            communes.some((com) => com.value === site?.municipalityId)
          )
          .map((site) => ({
            label: site.siteName,
            value: site._id,
          }));

        if (newSiteOptions.length > 0) {
          const newReservoirOptions = reservoirsOptionsInfo
            .filter((res) => newSiteOptions.some((s) => s.value === res.siteId))
            .map((reservoir) => ({
              label: reservoir?.name,
              value: reservoir._id,
            }));

          setOptionsarrayIds(newReservoirOptions);
          setSiteOptions(newSiteOptions);
        } else {
          setOptionsarrayIds([
            {
              label: "none",
              value: "1",
            },
          ]);
          setSiteOptions([
            {
              label: "none",
              value: "1",
            },
          ]);
        }
      }
      if (dairas.length > 0) {
        setOptionarrayDaira(dairas);
      }
    } else {
      defaultCityPopulation();
      if (!selectedCommune) {
        defaultSitePopulation();
        if (!selectedSite) {
          defaultReservoirPopulation();
        }
      }
    }
  }, [selectedWilaya]);
  useEffect(() => {
    if (selectedDaira) {
      let communes = [];

      cities.map((city) => {
        if (city.daira_name_ascii === selectedDaira) {
          setOptionarraywilaya([
            {
              label: `${city.wilaya_code}-${city.wilaya_name_ascii}`,
              value: city.wilaya_code,
            },
          ]);
          setOptionarrayCommune([]);
          if (!communes.some((item) => item.value === city._id)) {
            communes.push({
              label: city.commune_name_ascii,
              value: city._id,
            });
          }
        }
      });

      if (communes.length > 0) {
        setOptionarrayCommune(communes);
        const newSiteOptions = sites
          .filter((site) =>
            communes.some((com) => com.value === site?.municipalityId)
          )
          .map((site) => ({
            label: site.siteName,
            value: site._id,
          }));

        if (newSiteOptions.length > 0) {
          const newReservoirOptions = reservoirsOptionsInfo
            .filter((res) => newSiteOptions.some((s) => s.value === res.siteId))
            .map((reservoir) => ({
              label: reservoir?.name,
              value: reservoir._id,
            }));

          setSiteOptions(newSiteOptions);

          setOptionsarrayIds(newReservoirOptions);
        } else {
          setOptionsarrayIds([
            {
              label: "none",
              value: "1",
            },
          ]);
          setSiteOptions([
            {
              label: "none",
              value: "1",
            },
          ]);
        }
      }
    } else {
      if (!selectedWilaya) {
        defaultCityPopulation();
      }

      if (!selectedCommune && !selectedWilaya) {
        defaultSitePopulation();
      }

      if (!selectedSite && !selectedCommune && !selectedWilaya) {
        defaultReservoirPopulation();
      }
    }
  }, [selectedDaira]);
  useEffect(() => {
    if (selectedCommune) {
      let communes = [];
      cities.map((city) => {
        if (city._id === selectedCommune) {
          setOptionarraywilaya([
            {
              label: `${city.wilaya_code}-${city.wilaya_name_ascii}`,
              value: city.wilaya_code,
            },
          ]);
          setOptionarrayDaira([
            { label: city.daira_name_ascii, value: city.daira_name_ascii },
          ]);
        }
      });

      const newSiteOptions = sites
        .filter((site) => site?.municipalityId === selectedCommune)
        .map((site) => ({
          label: site.siteName,
          value: site._id,
        }));

      if (newSiteOptions.length > 0) {
        const newReservoirOptions = reservoirsOptionsInfo
          .filter((res) => newSiteOptions.some((s) => s.value === res.siteId))
          .map((reservoir) => ({
            label: reservoir?.name,
            value: reservoir._id,
          }));

        setOptionsarrayIds(newReservoirOptions);
        setSiteOptions(newSiteOptions);
      } else {
        setOptionsarrayIds([
          {
            label: "none",
            value: "1",
          },
        ]);
        setSiteOptions([
          {
            label: "none",
            value: "1",
          },
        ]);
      }
      if (communes.length > 0) {
        setOptionarrayCommune(communes);
      }
    } else {
      if (!selectedSite && !selectedDaira && !selectedWilaya) {
        defaultReservoirPopulation();
      }
      if (!selectedWilaya && !selectedDaira) {
        defaultCityPopulation();
      }

      if (!selectedCommune && !selectedDaira && !selectedWilaya) {
        defaultSitePopulation();
      }
    }
  }, [selectedCommune]);
  useEffect(() => {
    if (selectedSite) {
      const newReservoirOptions = reservoirsOptionsInfo
        .filter((reservoir) => reservoir.siteId === selectedSite)
        .map((reservoir) => ({
          label: reservoir?.name,
          value: reservoir._id,
        }));
      const selectedSiteInfo = sites.filter(
        (site) => site._id === selectedSite
      );
      cities
        .filter((city) => city._id === selectedSiteInfo[0]?.municipalityId)
        .map((city) => {
          setOptionarraywilaya([
            {
              label: `${city.wilaya_code}-${city.wilaya_name_ascii}`,
              value: city.wilaya_code,
            },
          ]);

          setOptionarrayDaira([
            {
              label: city.daira_name_ascii,
              value: city.daira_name_ascii,
            },
          ]);
          setOptionarrayCommune([
            {
              label: city.commune_name_ascii,
              value: city._id,
            },
          ]);
        });
      setOptionsarrayIds(newReservoirOptions);
    } else {
      if (!selectedCommune) {
        defaultCityPopulation();

        defaultReservoirPopulation();
        defaultSitePopulation();
      }
    }
  }, [selectedSite]);
  useEffect(() => {
    if (selectedReservoir) {
      const selectedReservoirInfo = reservoirsOptionsInfo.filter(
        (res) => res._id === selectedReservoir
      );
      const selectedSiteInfo = sites.filter(
        (site) => site._id === selectedReservoirInfo[0].siteId
      )[0];
      setSiteOptions([
        { label: selectedSiteInfo.siteName, value: selectedSiteInfo._id },
      ]);
      cities
        .filter((city) => city._id === selectedSiteInfo?.municipalityId)
        .map((city) => {
          setOptionarraywilaya([
            {
              label: `${city.wilaya_code}-${city.wilaya_name_ascii}`,
              value: city.wilaya_code,
            },
          ]);

          setOptionarrayDaira([
            {
              label: city.daira_name_ascii,
              value: city.daira_name_ascii,
            },
          ]);
          setOptionarrayCommune([
            {
              label: city.commune_name_ascii,
              value: city._id,
            },
          ]);
        });
    } else {
      if (!selectedSite) {
        defaultReservoirPopulation();
      }
      defaultSitePopulation();
      if (!selectedCommune) {
        defaultCityPopulation();
      }
    }
  }, [selectedReservoir]);

  const columnsFilters = [
    {
      name: "Wilaya",
      colName: t("Wilaya"),

      onChange: (e) => {
        setSelectedWilaya(e?.value);
        setpageFilters((filters) => {
          return { ...filters, wilaya_code: e?.value };
        });
      },
      placeHolder: "Wilaya",
      optionsarray: optionarraywilaya,
      type: "select",
    },
    {
      name: "Daira",

      colName: t("Daira"),
      onChange: (e) => {
        setSelectedDaira(e?.value);
        setpageFilters((filters) => {
          return { ...filters, daira_name_ascii: e?.value };
        });
      },
      placeHolder: "Daira",
      optionsarray: optionarrayDaira,
      type: "select",
    },
    {
      name: "Commune",

      colName: t("Commune"),
      onChange: (e) => {
        setSelectedCommune(e?.value);
        setpageFilters((filters) => {
          return { ...filters, municipalityId: e?.value };
        });
      },
      placeHolder: "Commune",
      optionsarray: optionarrayCommune,
      type: "select",
    },
    {
      name: "Site",

      colName: t("Site"),
      onChange: (e) => {
        setSelectedSite(e?.value);
        setpageFilters((filters) => {
          return { ...filters, siteId: e?.value };
        });
      },
      placeHolder: "Site",
      optionsarray: siteOptions,
      type: "select",
    },
    {
      name: "reservoir",
      colName: t("reservoir"),
      onChange: (e) => {
        setSelectedReservoir(e?.value);
        setpageFilters((filters) => {
          return { ...filters, reservoirId: e?.value };
        });
      },
      placeHolder: "Réservoirs ",
      optionsarray: optionsarrayIds,
      type: "select",
    },

    //wilaya daira, minicipality, site, reservoirs

    //dashboard 1 : check the offline/online devices + sync volume total with the online devices only
    // add 2 decimals to
  ];
  const filterData = () => {
    console.log(pagefilters);
    getReservoirs(pagefilters);
  };
  return (
    <div>
      <Col xl={12}>
        <Card>
          <div className=" ">
            <Dashboard2Filter
              fieldsToRender={columnsFilters}
              onFilterButtonClick={filterData}
              onCancelButtonClick={cancelFilter}
            />
          </div>
        </Card>
      </Col>
      <Row>
        {reservoirsList.map((reservoir, index) => (
          <Col
            key={reservoir._id}
            xl={4}
            lg={4}
            md={6}
            sm={12}
            style={{ marginBottom: "1rem" }}
          >
            <Card>
              <CardHeader>
                <h4 className="mb-0">{reservoir?.name || "ADE"}</h4>
              </CardHeader>
              <PieChart
                series={[
                  parseInt(
                    reservoir.currentFilledPercentage *
                      0.01 *
                      reservoir.capacityMeterCubic
                  ),
                  parseInt(
                    reservoir.capacityMeterCubic -
                      reservoir.currentFilledPercentage *
                        0.01 *
                        reservoir.capacityMeterCubic
                  ),
                ]}
                options={options}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Dashboard2;
