//import { usesitesStore } from "../../stores/Sites";
import { useEffect, useState } from "react";
import { t } from "i18next";
import {
  Filter,
  DefaultColumnFilter,
  SelectColumnFilter,
} from "../../Components/Common/filters";
import { WaterFilter2 } from "../Access/History/WaterFilters";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { useAuth } from "../../stores";
import DataTableBase from "../../Components/Common/DataTableBase/DataTableBase";
import moment from "moment";
import { useDashboard1Store } from "../../stores/dashboard1";
import { useCitiesStore } from "../../stores/Cities";
import { useWaterStore } from "../../stores/WaterTank";
import { ZoomableTimeseries } from "./testChart";
import * as XLSX from "xlsx";
import { usesitesStore } from "../../stores/SiteStore";

const WaterVolumReports = () => {
  const title = t("ADE | Reports");
  document.title = title;
  const { getDashboard1Data, reservoirs, filters } = useDashboard1Store(
    (state) => state
  );

  const { history, getReservoirHistory, isLoading } = useWaterStore(
    (state) => state
  );

  const { sites, getSites } = usesitesStore((state) => state);

  const { getCities, cities, setFilters } = useCitiesStore((state) => state);
  const [pagefilters, setpageFilters] = useState({});
  const cancelFilter = () => {
    getReservoirHistory({});
    setFilters({});
    setpageFilters({});
  };
  const [tableData, setTableData] = useState([]);
  const [tableDataPerPage, setTableDataPerPage] = useState([]);
  const [optionsarrayIds, setOptionsarrayIds] = useState([
    {
      label: "none",
      value: "1",
    },
  ]);
  //fetch data for dashboard1
  useEffect(() => {
    getDashboard1Data && getDashboard1Data();
    getCities && getCities(filters);
    getReservoirHistory && getReservoirHistory();
    getSites && getSites();
    // fetch data from backend  eavry 5 seconds
    const intervalId = setInterval(getDashboard1Data, 500000000);
    return () => clearInterval(intervalId);
  }, []);

  const [citiesList, setCitiesList] = useState([]);

  // set options for wilaya filters + daira + communes if needed
  const [optionarraywilaya, setOptionarraywilaya] = useState([
    {
      label: "loading",
      value: "1",
    },
  ]);
  const [optionarrayDaira, setOptionarrayDaira] = useState([
    {
      label: "loading",
      value: "1",
    },
  ]);
  const [optionarrayCommune, setOptionarrayCommune] = useState([
    {
      label: "loading",
      value: "1",
    },
  ]);
  const [siteOptions, setSiteOptions] = useState([
    {
      label: "loading",
      value: "1",
    },
  ]);

  const [selectedWilaya, setSelectedWilaya] = useState("");
  const [selectedDaira, setSelectedDaira] = useState("");
  const [selectedCommune, setSelectedCommune] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedReservoir, setSelectedReservoir] = useState("");
  useEffect(() => {
    if (cities.length > 0) {
      setCitiesList(cities);
    }
  }, [cities]);

  const defaultCityPopulation = () => {
    let wilayas = [];
    let dairas = [];
    let communes = [];
    citiesList.map((city) => {
      // remoove repeated wilaya names and set the wilaya option wilayas
      if (
        !wilayas.some(
          (item) =>
            item.label === `${city.wilaya_code}-${city.wilaya_name_ascii}` &&
            item.value === city.wilaya_code
        )
      ) {
        {
          wilayas.push({
            label: `${city.wilaya_code}-${city.wilaya_name_ascii}`,
            value: city.wilaya_code,
          });
        }
        wilayas.sort((a, b) => a.value - b.value);
        setOptionarraywilaya(wilayas);
      }
      //
      if (!dairas.some((item) => item.label === city.daira_name_ascii)) {
        dairas.push({
          label: city.daira_name_ascii,
          value: city.daira_name_ascii,
        });

        setOptionarrayDaira(dairas);
      }
      if (!communes.some((item) => item.value === city._id)) {
        communes.push({
          label: city.commune_name_ascii,
          value: city._id,
        });

        setOptionarrayCommune(communes);
      }
    });
  };

  const defaultSitePopulation = () => {
    const formattedSites = sites.map((site) => {
      return {
        label: site.siteName,
        value: site._id,
      };
    });
    setSiteOptions(formattedSites);
  };
  const defaultReservoirPopulation = () => {
    let array = [];
    reservoirs.map((reservoir) => {
      array.push({
        label: reservoir?.name,
        value: reservoir._id,
      });
      setOptionsarrayIds(array);
    });
  };
  useEffect(() => {
    defaultCityPopulation();
  }, [citiesList]);
  useEffect(() => {
    defaultSitePopulation();
  }, [sites]);

  useEffect(() => {
    defaultReservoirPopulation();
  }, [reservoirs]);

  useEffect(() => {
    if (selectedWilaya) {
      let dairas = [];
      let communes = [];
      setOptionarrayDaira([
        {
          label: "none",
          value: "1",
        },
      ]);
      setOptionarrayCommune([
        {
          label: "none",
          value: "1",
        },
      ]);
      cities.map((city) => {
        if (city.wilaya_code === selectedWilaya) {
          if (!dairas.some((item) => item.label === city.daira_name_ascii)) {
            dairas.push({
              label: city.daira_name_ascii,
              value: city.daira_name_ascii,
            });
          }
          if (!communes.some((item) => item.value === city._id)) {
            communes.push({
              label: city.commune_name_ascii,
              value: city._id,
            });
          }
        }
      });

      if (communes.length > 0) {
        setOptionarrayCommune(communes);
        const newSiteOptions = sites
          .filter((site) =>
            communes.some((com) => com.value === site?.municipalityId)
          )
          .map((site) => ({
            label: site.siteName,
            value: site._id,
          }));

        if (newSiteOptions.length > 0) {
          const newReservoirOptions = reservoirs
            .filter((res) => newSiteOptions.some((s) => s.value === res.siteId))
            .map((reservoir) => ({
              label: reservoir?.name,
              value: reservoir._id,
            }));

          setOptionsarrayIds(newReservoirOptions);
          setSiteOptions(newSiteOptions);
        } else {
          setOptionsarrayIds([
            {
              label: "none",
              value: "1",
            },
          ]);
          setSiteOptions([
            {
              label: "none",
              value: "1",
            },
          ]);
        }
      }
      if (dairas.length > 0) {
        setOptionarrayDaira(dairas);
      }
    } else {
      defaultCityPopulation();
      if (!selectedCommune) {
        defaultSitePopulation();
        if (!selectedSite) {
          defaultReservoirPopulation();
        }
      }
    }
  }, [selectedWilaya]);
  useEffect(() => {
    if (selectedDaira) {
      let communes = [];

      cities.map((city) => {
        if (city.daira_name_ascii === selectedDaira) {
          setOptionarraywilaya([
            {
              label: `${city.wilaya_code}-${city.wilaya_name_ascii}`,
              value: city.wilaya_code,
            },
          ]);
          setOptionarrayCommune([]);
          if (!communes.some((item) => item.value === city._id)) {
            communes.push({
              label: city.commune_name_ascii,
              value: city._id,
            });
          }
        }
      });

      if (communes.length > 0) {
        setOptionarrayCommune(communes);
        const newSiteOptions = sites
          .filter((site) =>
            communes.some((com) => com.value === site?.municipalityId)
          )
          .map((site) => ({
            label: site.siteName,
            value: site._id,
          }));

        if (newSiteOptions.length > 0) {
          const newReservoirOptions = reservoirs
            .filter((res) => newSiteOptions.some((s) => s.value === res.siteId))
            .map((reservoir) => ({
              label: reservoir?.name,
              value: reservoir._id,
            }));

          setSiteOptions(newSiteOptions);

          setOptionsarrayIds(newReservoirOptions);
        } else {
          setOptionsarrayIds([
            {
              label: "none",
              value: "1",
            },
          ]);
          setSiteOptions([
            {
              label: "none",
              value: "1",
            },
          ]);
        }
      }
    } else {
      if (!selectedWilaya) {
        defaultCityPopulation();
      }

      if (!selectedCommune && !selectedWilaya) {
        defaultSitePopulation();
      }

      if (!selectedSite && !selectedCommune && !selectedWilaya) {
        defaultReservoirPopulation();
      }
    }
  }, [selectedDaira]);
  useEffect(() => {
    if (selectedCommune) {
      let communes = [];
      cities.map((city) => {
        if (city._id === selectedCommune) {
          setOptionarraywilaya([
            {
              label: `${city.wilaya_code}-${city.wilaya_name_ascii}`,
              value: city.wilaya_code,
            },
          ]);
          setOptionarrayDaira([
            { label: city.daira_name_ascii, value: city.daira_name_ascii },
          ]);
        }
      });

      const newSiteOptions = sites
        .filter((site) => site?.municipalityId === selectedCommune)
        .map((site) => ({
          label: site.siteName,
          value: site._id,
        }));

      if (newSiteOptions.length > 0) {
        const newReservoirOptions = reservoirs
          .filter((res) => newSiteOptions.some((s) => s.value === res.siteId))
          .map((reservoir) => ({
            label: reservoir?.name,
            value: reservoir._id,
          }));

        setOptionsarrayIds(newReservoirOptions);
        setSiteOptions(newSiteOptions);
      } else {
        setOptionsarrayIds([
          {
            label: "none",
            value: "1",
          },
        ]);
        setSiteOptions([
          {
            label: "none",
            value: "1",
          },
        ]);
      }
      if (communes.length > 0) {
        setOptionarrayCommune(communes);
      }
    } else {
      if (!selectedSite && !selectedDaira && !selectedWilaya) {
        defaultReservoirPopulation();
      }
      if (!selectedWilaya && !selectedDaira) {
        defaultCityPopulation();
      }

      if (!selectedCommune && !selectedDaira && !selectedWilaya) {
        defaultSitePopulation();
      }
    }
  }, [selectedCommune]);
  useEffect(() => {
    if (selectedSite) {
      const newReservoirOptions = reservoirs
        .filter((reservoir) => reservoir.siteId === selectedSite)
        .map((reservoir) => ({
          label: reservoir?.name,
          value: reservoir._id,
        }));
      const selectedSiteInfo = sites.filter(
        (site) => site._id === selectedSite
      );
      cities
        .filter((city) => city._id === selectedSiteInfo[0]?.municipalityId)
        .map((city) => {
          setOptionarraywilaya([
            {
              label: `${city.wilaya_code}-${city.wilaya_name_ascii}`,
              value: city.wilaya_code,
            },
          ]);

          setOptionarrayDaira([
            {
              label: city.daira_name_ascii,
              value: city.daira_name_ascii,
            },
          ]);
          setOptionarrayCommune([
            {
              label: city.commune_name_ascii,
              value: city._id,
            },
          ]);
        });
      setOptionsarrayIds(newReservoirOptions);
    } else {
      if (!selectedCommune) {
        defaultCityPopulation();

        defaultReservoirPopulation();
        defaultSitePopulation();
      }
    }
  }, [selectedSite]);
  useEffect(() => {
    if (selectedReservoir) {
      const selectedReservoirInfo = reservoirs.filter(
        (res) => res._id === selectedReservoir
      );
      const selectedSiteInfo = sites.filter(
        (site) => site._id === selectedReservoirInfo[0].siteId
      )[0];
      setSiteOptions([
        { label: selectedSiteInfo.siteName, value: selectedSiteInfo._id },
      ]);
      cities
        .filter((city) => city._id === selectedSiteInfo?.municipalityId)
        .map((city) => {
          setOptionarraywilaya([
            {
              label: `${city.wilaya_code}-${city.wilaya_name_ascii}`,
              value: city.wilaya_code,
            },
          ]);

          setOptionarrayDaira([
            {
              label: city.daira_name_ascii,
              value: city.daira_name_ascii,
            },
          ]);
          setOptionarrayCommune([
            {
              label: city.commune_name_ascii,
              value: city._id,
            },
          ]);
        });
    } else {
      if (!selectedSite) {
        defaultReservoirPopulation();
      }
      defaultSitePopulation();
      if (!selectedCommune) {
        defaultCityPopulation();
      }
    }
  }, [selectedReservoir]);

  useEffect(() => {
    const InterpolatedData = [];
    setTableData([]);
    console.log(history);
    // Collecting and formatting data
    Object.keys(history).forEach((date) => {
      Object.keys(history[date]).forEach((hour) => {
        const [year, month, day] = date.split("-").map(Number);
        const formattedHour = parseInt(hour); // Assuming hour contains the hour

        const unformattedDate = new Date(year, month - 1, day, formattedHour); // Month in JavaScript Date starts from 0

        const thedate = moment(unformattedDate).format("YYYY-MM-DD HH:mm");

        let filledCapacity = parseFloat(
          history[date][formattedHour].averageFilledCubic
        );
        let emptyCubicMeters = parseFloat(
          history[date][formattedHour].averageEmptyCubic
        );
        let filledPercentage = parseFloat(
          history[date][formattedHour].averageEmptyPercentage
        );
        let emptyPercentage =
          100 -
          parseFloat(history[date][formattedHour].averageFilledPercentage);

        InterpolatedData.push({
          date: thedate,
          filledCapacity,
          emptyCubicMeters,
          filledPercentage,
          emptyPercentage,
        });
      });
    });

    // Sorting data by timestamp in reverse order
    InterpolatedData.sort((a, b) => new Date(b.date) - new Date(a.date));

    setTableData(InterpolatedData);
  }, [history]);

  useEffect(() => {
    const startIndex = 0;
    const slicedData = tableData.slice(startIndex, startIndex + 10);
    console.log(slicedData);
    setTableDataPerPage(slicedData);
  }, [tableData]);

  const columnsFilters = [
    {
      name: "Date",
      colName: t("Date"),
      onChange: (e) => {
        setpageFilters((filters) => {
          return { ...filters, dateFrom: e[0], dateTo: e[1] };
        });
      },
      type: "date",
    },
    {
      name: "Wilaya",
      colName: t("Wilaya"),

      onChange: (e) => {
        setSelectedWilaya(e?.value);
        setpageFilters((filters) => {
          return { ...filters, wilaya_code: e?.value };
        });
      },
      placeHolder: "wilaya",
      optionsarray: optionarraywilaya,
      type: "select",
    },
    {
      name: "Daira",

      colName: t("Daira"),
      onChange: (e) => {
        setSelectedDaira(e?.value);
        setpageFilters((filters) => {
          return { ...filters, daira_name_ascii: e?.value };
        });
      },
      placeHolder: "Daira",
      optionsarray: optionarrayDaira,
      type: "select",
    },
    {
      name: "Commune",

      colName: t("Commune"),
      onChange: (e) => {
        setSelectedCommune(e?.value);
        setpageFilters((filters) => {
          return { ...filters, municipalityId: e?.value };
        });
      },
      placeHolder: "Commune",
      optionsarray: optionarrayCommune,
      type: "select",
    },
    {
      name: "Site",

      colName: t("Site"),
      onChange: (e) => {
        setSelectedSite(e?.value);
        setpageFilters((filters) => {
          return { ...filters, siteId: e?.value };
        });
      },
      placeHolder: "Site",
      optionsarray: siteOptions,
      type: "select",
    },
    {
      name: "reservoir",
      colName: t("reservoir"),
      onChange: (e) => {
        setSelectedReservoir(e?.value);
        setpageFilters((filters) => {
          return { ...filters, reservoirId: e?.value };
        });
      },
      placeHolder: "Réservoirs ",
      optionsarray: optionsarrayIds,
      type: "select",
    },

    //wilaya daira, minicipality, site, reservoirs

    //dashboard 1 : check the offline/online devices + sync volume total with the online devices only
    // add 2 decimals to
  ];

  const filterData = () => {
    getReservoirHistory(pagefilters);
  };
  function formatM3(value) {
    // Convert the value to string
    value = String(value);

    // Split the string into parts separated by every three characters
    let parts = [];
    while (value.length > 3) {
      parts.unshift(value.slice(-3));
      value = value.slice(0, -3);
    }
    parts.unshift(value);

    // Join the parts with space as separator
    return parts.join(" ");
  }

  const columns = [
    {
      name: t("#"),
      width: "50px",
      selector: (row) => tableData.indexOf(row) + 1,
      sortable: false,
      wrap: true,
    },

    {
      name: t("Date"),
      width: "150px",
      selector: (row) => row.date,

      sortable: false,
      wrap: true,
    },
    //why do we need the date ?
    {
      name: t("Volume Rempli (M3)"),
      // width: "100px",
      selector: (row) => row.filledCapacity,
      sortable: false,
      wrap: true,
      cell: (row) => (
        <strong
          style={{
            margin: "0 auto",
            color: "" === "" && "#7FC7D9",
            textAlign: "left",
          }}
        >
          {formatM3(parseInt(row.filledCapacity))}
        </strong>
      ),
    },
    {
      name: t("Volume Vide (M3)"),
      // width: "100px",
      selector: (row) => row.emptyCubicMeters,
      sortable: false,
      wrap: true,
      cell: (row) => (
        <strong
          style={{
            margin: "0 auto",
            color: "" === "NUMILOG" && "#7FC7D9",
          }}
        >
          {formatM3(parseInt(row.emptyCubicMeters))}
        </strong>
      ),
    },
    {
      name: t("Pourcentage Rempli (%)"),
      // width: "100px",
      selector: (row) => row.filledPercentage,
      sortable: false,
      wrap: true,
      cell: (row) => (
        <strong
          style={{
            margin: "0 auto",
            color: "" === "NUMILOG" && "#7FC7D9",
          }}
        >
          {parseInt(row.filledPercentage)}
        </strong>
      ),
    },

    {
      name: t("Pourcentage Vide (%)"),
      // width: "100px",
      selector: (row) => 100 - row.emptyPercentage,
      sortable: false,
      wrap: true,
      cell: (row) => (
        <strong
          style={{
            margin: "0 auto",
            color: "" === "NUMILOG" && "#7FC7D9",
          }}
        >
          {parseInt(row.emptyPercentage)}
        </strong>
      ),
    },
  ];

  const onChangePage = (page) => {
    const startIndex = (page - 1) * 10;
    const slicedData = tableData.slice(startIndex, startIndex + 10);
    setTableDataPerPage(slicedData);
  };

  const handleExport = () => {
    // Convert data to Excel format
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "reservoir historique");

    // Save the Excel file
    XLSX.writeFile(
      wb,
      `historique-${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}.xlsx`
    );
  };

  return (
    <>
      <Col xl={12}>
        <Card>
          <div className=" ">
            <WaterFilter2
              fieldsToRender={columnsFilters}
              onFilterButtonClick={filterData}
              onCancelButtonClick={cancelFilter}
            />
          </div>
        </Card>
      </Col>
      {/* <Col xl={12}>
        <Card>
          <CardBody>
    
            <LineChart
              dataPlusTime={history}
              dataColors='["--vz-secondary-rgb, 0.2","--vz-secondary", "--vz-danger-rgb, 0.2", "--vz-danger"]'
            />
          </CardBody>
        </Card>
      </Col> */}
      <Col xl={12}>
        <Card>
          <CardBody>
            {/* <LineChart  dataColors='["--vz-primary-rgb, 0.2","--vz-primary", "--vz-success-rgb, 0.2", "--vz-success"]'/>*/}

            <ZoomableTimeseries
              isLoading={isLoading}
              history={history}
              dataColors={
                '["--vz-secondary-rgb, 0.2","--vz-secondary", "--vz-danger-rgb, 0.2", "--vz-danger"]'
              }
            />
          </CardBody>
        </Card>
      </Col>

      <Col xl={12}>
        {tableData ? (
          <DataTableBase
            data={tableDataPerPage}
            columns={columns}
            //loading={isLoading}
            paginationTotalRows={tableData.length}
            onChangePage={onChangePage}
            // onChangeRowsPerPage={onChangeRowsPerPage}
            // onHeaderAddBtnClick={toggleCreateModal}
            onHeaderDeleteBtnClick={() => {
              alert("Soon");
            }}
            // onRowEditBtnClick={setToFalsePositive}
            // onRowDeleteBtnClick={deleteHistory}
            // onSearchIconClick={searchHandler}
            actionColWidth="100px"
            showActionButtons={false}
            showActionColumn={false}
            showSelectBox={false}
            // showSearch={true}
            // showSubHeader={true}
            // showActionButtons={true}
            //onHeaderAddBtnClick={toggleAddModal}
            //onRowEditBtnClick={toggleEditModal}
            //onRowDeleteBtnClick={deleteHistory}
            //showActionColumn={user?.roles?.includes("superAdmin") ? true : false}
            // customActionBtns={(row) => (
            //   <>

            //     <button
            //       className="btn btn-sm btn-danger"
            //       onClick={() => {
            //         showConfirm(() => {
            //           deleteHistory(row._id);
            //         });
            //       }}
            //       title="Delete"
            //     >
            //       <i className="ri-delete-bin-line"></i>
            //     </button>
            //   </>
            // )}
          >
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",

                alignItems: "center",
              }}
            >
              <Col>
                <h4 className="card-title mb-0">Tableau</h4>
              </Col>

              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",

                  paddingRight: "0px",
                }}
              >
                {" "}
                <button
                  className=" btn btn-secondary waves-effect waves-light"
                  onClick={() => {
                    handleExport();
                  }}
                  // onClick={props.toggleMapTable}
                >
                  {t("Download")}
                </button>
              </Col>
            </CardHeader>
          </DataTableBase>
        ) : (
          <div>Loading...</div>
        )}
      </Col>
    </>
  );
};
export default WaterVolumReports;
