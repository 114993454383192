import { create } from "zustand";
import { axiosHelper } from "./helpers";
import { filterNonFalseValues } from "./helpers/Utlis";

export const usesitesStore = create((set, get) => ({
  sites: [],
  isLoading: false,
  isError: false,
  error: undefined,
  filters: null,
  resultsCount: undefined,
  // Methods
  getSites: async (filters) => {
    // set({ filters: filterNonFalseValues(filters) });
    // console.log(filterNonFalseValues(filters))
    const body = {
      search: filterNonFalseValues(filters)?.search,
      // sortDirection: "desc",
      // sortField: "createdAt",
      // dateFrom: "",
      // dateTo: "",
      page: filters?.page || 1,
      limit: filters?.perPage || 10,
    };

    try {
      set({ isLoading: true });
      let response = await axiosHelper.post("/system/site/get");
      if (!response.result) {
        return;
      }

      set({
        sites: response.data,
        resultsCount: response.count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      set({ isLoading: false });
    }
  },
  createSite: async (body) => {
    set({ isLoading: true });
    try {
      let response = await axiosHelper.post("/system/site/create", body);
      if (!response.result) {
        return;
      }
      get().getSites();
      return response.result;
    } catch (error) {
      return error.response.data.message;
    } finally {
      set({ isLoading: false });
    }
  },
}));
