import { GoogleMap, Marker } from "@react-google-maps/api";
//import * as parkData from "./data/skateboard-parks.json";
import { useMapStore } from "../../stores/Map";

const Map=()=>{
const  parkData=useMapStore((state)=>state.sites[0])
console.log("sites", parkData)
    return(<div>Map for 
   
    </div>)
}
export default Map;