import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Line, Bar, Pie, Doughnut, Polar, Radar } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
//import { AudiencesCharts } from './DashboardAnalyticsCharts';
//import { getAudiencesMetricsChartsData } from "../../store/dashboardAnalytics/action";
import moment from "moment";
import { useWaterStore } from "../../../../stores/WaterTank";

const WaterMaxChart = ({ dataColors, series, dataTime }) => {
  const getChartColorsArray = useWaterStore(
    (state) => state.getChartColorsArray
  );
  const chartColors = getChartColorsArray(dataColors);
  const [dates, setDates] = useState([]);
  useEffect(() => {
    if (dataTime) {
      console.log(dataTime.slice(2));
      setDates(dataTime.slice(2));
    }
  }, [dataTime]);

  const options = {
    chart: {
      type: "bar",
      height: 309,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        borderRadius: 6,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontWeight: 400,
      fontSize: "8px",
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 9,
        height: 9,
        radius: 4,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    grid: {
      show: false,
    },
    colors: chartColors,
    xaxis: {
      categories: ["aujourd'hui", "Hier", ...dates],
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        strokeDashArray: 1,
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return val.toFixed(0) + " m³";
        },
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="bar"
        height="309"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

// export default WaterMaxChart;

export const WaterMaxValues = ({ dayMaxVolumData, datatime }) => {
  //it is not using the data time stamp
  const [chartData, setchartData] = useState([]);
  /*
    const { audiencesMetricsData } = useSelector(state => ({
        audiencesMetricsData: state.DashboardAnalytics.audiencesMetricsData
    }));
*/
  useEffect(() => {
    console.log("data chart recieved from parent", dayMaxVolumData);
    setchartData(
      [
        {
          name: "Max Volume",
          data: dayMaxVolumData,
        },
      ]

      //[ 45, 25,30,45, 25,30,45, 25,30,45,]
    );
  }, [dayMaxVolumData]);

  /*
    const onChangeChartPeriod = pType => {
        dispatch(getAudiencesMetricsChartsData(pType));
    };

    useEffect(() => {
        dispatch(getAudiencesMetricsChartsData("all"));
    }, [dispatch]);
*/
  return (
    <React.Fragment>
      <Col xl={8}>
        <Card>
          <CardHeader className="border-0 align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Volume maximum en m <sup>3</sup> sur 10 jours
            </h4>
            {/* <div className="d-flex gap-1">
                            <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => { onChangeChartPeriod("all"); }}>
                                ALL
                            </button>
                            <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => { onChangeChartPeriod("monthly"); }}>
                                1M
                            </button>
                            <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => { onChangeChartPeriod("halfyearly"); }}>
                                6M
                            </button>
                            <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => { onChangeChartPeriod("yearly"); }}>
                                1Y
                            </button>
    </div>*/}
          </CardHeader>
          <CardHeader className="p-0 border-0 bg-soft-light">
            {/*<Row className="g-0 text-center">
                            <Col xs={6} sm={4}>
                                <div className="p-3 border border-dashed border-start-0">
                                    <h5 className="mb-1"><span className="counter-value" data-target="854">
                                        <CountUp
                                            start={0}
                                            end={854}
                                            duration={3}
                                        />
                                    </span>
                                        <span className="text-success ms-1 fs-13">49%<i className="ri-arrow-right-up-line ms-1 align-middle"></i></span>
                                    </h5>
                                    <p className="text-muted mb-0">Avg. Session</p>
                                </div>
                            </Col>
                            <Col xs={6} sm={4}>
                                <div className="p-3 border border-dashed border-start-0">
                                    <h5 className="mb-1"><span className="counter-value" data-target="1278">
                                        <CountUp
                                            start={0}
                                            end={1278}
                                            duration={3}
                                            separator=","
                                        />
                                    </span>
                                        <span className="text-success ms-1 fs-13">60%<i className="ri-arrow-right-up-line ms-1 align-middle"></i></span>
                                    </h5>
                                    <p className="text-muted mb-0">Conversion Rate</p>
                                </div>
                            </Col>
                            <Col xs={6} sm={4}>
                                <div className="p-3 border border-dashed border-start-0 border-end-0">
                                    <h5 className="mb-1"><span className="counter-value" data-target="3">
                                        <CountUp
                                            start={0}
                                            end={3}
                                            duration={3}
                                        />
                                    </span>m <span className="counter-value" data-target="40">
                                            <CountUp
                                                start={0}
                                                end={40}
                                                duration={3}
                                            />
                                        </span>sec
                                        <span className="text-success ms-1 fs-13">37%<i className="ri-arrow-right-up-line ms-1 align-middle"></i></span>
                                    </h5>
                                    <p className="text-muted mb-0">Avg. Session Duration</p>
                                </div>
                            </Col>
</Row>*/}
          </CardHeader>
          <CardBody className="p-0 pb-2">
            <div>
              <WaterMaxChart
                series={chartData}
                dataTime={datatime}
                dataColors='["--vz-secondary", "--vz-gray-200"]'
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export const LineChart = ({ dataPlusTime, dataColors }) => {
  const getChartColorsArray = useWaterStore(
    (state) => state.getChartColorsArray
  );
  const lineChartColor = getChartColorsArray(dataColors);
  const labels = Object.keys(dataPlusTime);

  const interpolateData = (data, labelCount) => {
    const interpolatedData = [];
    const factor = Math.ceil(data.length / labelCount);
    for (let i = 0; i < data.length - 1; i++) {
      interpolatedData.push(data[i]);
      for (let j = 1; j < factor; j++) {
        interpolatedData.push((data[i] + data[i + 1]) / 2);
      }
    }
    interpolatedData.push(data[data.length - 1]);
    return interpolatedData;
  };

  useEffect(() => {
    const InterpolatedData = [];
    Object.keys(dataPlusTime).map((date) => {
      const dataToInterpolatePerDay = [];

      Object.keys(dataPlusTime[date]).map((data) => {
        // console.log(dataPlusTime[date][data] );

        dataToInterpolatePerDay.push(
          dataPlusTime[date][data][0].emptyCubicMeters
        );
      });
      InterpolatedData.push(dataToInterpolatePerDay);
    });
    console.log(InterpolatedData);
  }, []);
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Volume rempli",
        fill: true,
        lineTension: 0.5,
        backgroundColor: lineChartColor[0],
        borderColor: lineChartColor[1],
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineChartColor[1],
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineChartColor[1],
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          650, 590, 800, 810, 560, 550, 400, 550, 300, 800, 85, 20, 80, 85, 20,
          80,
        ],
        //we modify the data here
        //calculating data points:
      },
      {
        label: "Volume vide",
        fill: true,
        lineTension: 0.5,
        backgroundColor: lineChartColor[2],
        borderColor: lineChartColor[3],
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineChartColor[3],
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineChartColor[3],
        pointHoverBorderColor: "#eef0f2",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [800, 2300, 560, 650, 230, 350, 850, 250, 920, 360],
      },
    ],
  };
  // const stepSize = Math.ceil(data.datasets[0].data.length / data.labels.length);
  // console.log(stepSize);
  const options = {
    scales: {
      x: {
        ticks: {
          font: {
            family: "Poppins",
          },
          // stepSize: stepSize,
        },
      },
      y: {
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Line width={723} height={320} data={data} />
    </React.Fragment>
  );
};
