import { create } from "zustand";

export const useMapStore = create((set, get) => ({
    sites: [
        {
            "features": [{
              "properties": {
                "PARK_ID": 960,
                "NAME": "Bearbrook Skateboard Park",
                "DESCRIPTION": "Flat asphalt surface, 5 components"
              },
              "geometry": {
                "coordinates": [-75.3372987731628, 45.383321536272049]
              }
            }, {
              "properties": {
                "PARK_ID": 1219,
                "NAME": "Bob MacQuarrie Skateboard Park (SK8 Extreme Park)",
                "DESCRIPTION": "Flat asphalt surface, 10 components, City run learn to skateboard programs, City run skateboard camps in summer"
              },
              "geometry": {
                "coordinates": [-75.546518086577947, 45.467134581917357]
              }
            }, {
              "properties": {
                "PARK_ID": 1157,
                "NAME": "Walter Baker Skateboard Park",
                "DESCRIPTION": "Concrete bowl, 7,000 sq ft"
              },
              "geometry": {
                "coordinates": [-75.898610599532319, 45.295014379864874]
              }
            }]
          }
          
    ],
    isLoading: false,
    isError: false,
    error: undefined,

    // Methods
    
}))